import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Topbar.scss";

const Topbar = () => {
  const { t, i18n } = useTranslation();
  return (
    <div style={{ fontSize: 12 }} className="top-bar">
      <Link
        style={{ marginLeft: 4 }}
        className="white-font"
        target="_blank"
        to={"https://evaluation.eecertification.com/certifications/new"}
      >
        {t("header.certLink")}
      </Link>
    </div>
  );
};

export default Topbar;
